import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors, colorTransparency } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';
import { font } from '../../../styles/atoms/typography';

export const Container = styled.div`
  width: 100%;
  max-width: 770px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  background: linear-gradient(
    90.26deg,
    ${colors.primary[800]} 20.62%,
    ${colors.primary[900]} 72.62%
  );
  ${shadow('conversionPanel')}
  border-radius: 16px;
  padding-top: 40px;
  margin: 0px auto 20px;
  overflow: hidden;

  ${atMinWidth.sm`
    margin-bottom: 40px;
  `}

  ${atMinWidth.md`
    margin-bottom: 0;
  `}

  ${atMinWidth.lg`
    padding-top: 72px;
    gap: 57px;
  `}

  ${atMinWidth.xl`
    max-width: 100%;
    flex-direction: row;
    align-items: flex-end;
    gap: 32px;
  `}

  .cta-wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    margin-right: auto;

    ${atMinWidth.md`
      flex-direction: row;
    `}
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0px 32px;

  ${atMinWidth.lg`
    padding: 0px 72px;
  `}

  ${atMinWidth.xl`
    max-width: 570px;
    padding: 0px 0px 129px 70px;
  `}

  .heading {
    ${font('display', 'md', '700')}
    color: ${colors.base.white};

    ${atMinWidth.sm`
      ${font('display', 'lg', '700')}
    `}
  }

  .subhead {
    ${font('text', 'sm', '400')}
    color: ${colors.gray[100]};

    ${atMinWidth.sm`
      ${font('text', 'md', '400')}
    `}
  }

  && .app-store-badge {
    width: 175px;
    padding: 0px;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  filter: drop-shadow(
    0px 4px 4px ${(colorTransparency(colors.base.black), 0.25)}
  );

  .gatsby-image-wrapper {
    width: calc(100% - 32px);
    height: 100%;

    ${atMinWidth.lg`
      width: calc(100% - 136px);
    `}

    ${atMinWidth.xl`
      width: 100%;
    `}
  }
`;
